import { Injectable } from "@angular/core";
import { Personalization } from "src/app/interfaces/personalization.interface";

@Injectable({
    providedIn: 'root'
})
export class PersonalizationService {

    personalization: Personalization;

    constructor() {
    }

    public getPersonalization(): Personalization {
        if(!this.personalization) {

            
            const root = document.documentElement;
            if(localStorage.getItem('personalization')) {
                this.personalization = JSON.parse(localStorage.getItem('personalization'));
                root.style.setProperty('--x-primary-color', this.personalization.primary_color);
                root.style.setProperty('--x-primer-degradado', this.personalization.primer_degradado);
                root.style.setProperty('--x-segundo-degradado', this.personalization.segundo_degradado);
                root.style.setProperty('--x-dark-mode', this.personalization.dark_mode);
                root.style.setProperty('--x-fondo-registro', this.personalization.fondo_registro);
                root.style.setProperty('--x-text-primary', this.personalization.text_primary);
            } else {
                root.style.setProperty('--x-primary-color', '#f91b7b');
                root.style.setProperty('--x-primer-degradado', '#9d29db');
                root.style.setProperty('--x-segundo-degradado', '#ee7e45');
                root.style.setProperty('--x-dark-mode', '#061826');
                root.style.setProperty('--x-fondo-registro', '#061826');
                root.style.setProperty('--x-text-primary', '#ffffff');
                this.personalization = {
                    logo: '/assets/logo/logologinlight.png',
                    logo_pwa: '/assets/icons/icon-512x512.png',
                    fondo_registro: '#061826',
                    primary_color: '#f91b7b',
                    text_primary: '#ffffff',
                    primer_degradado: '#9d29db',
                    segundo_degradado: '#ee7e45',
                    dark_mode: '#061826'
                };
            }

        }
        return this.personalization;
    }

    public setPersonalization(personalization: Personalization) {
        localStorage.setItem('personalization', JSON.stringify(personalization));
        
        this.personalization = personalization;
        
        const root = document.documentElement;
        root.style.setProperty('--x-primary-color', this.personalization.primary_color);
        root.style.setProperty('--x-primer-degradado', this.personalization.primer_degradado);
        root.style.setProperty('--x-segundo-degradado', this.personalization.segundo_degradado);
        root.style.setProperty('--x-dark-mode', this.personalization.dark_mode);
        root.style.setProperty('--x-fondo-registro', this.personalization.fondo_registro);
        root.style.setProperty('--x-text-primary', this.personalization.text_primary);
    }

}