/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Apiv2Service {

  constructor(
    private http: HttpClient
  ) { }


  public get(url: string) {
    const token = localStorage.getItem('token');
    return this.http.get(environment.v2 + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
    });
  }

  public post(url: string, body: any) {
    const token = localStorage.getItem('token');
    return this.http.post(environment.v2 + url, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
    })
  }
}
